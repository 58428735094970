import { render, staticRenderFns } from "./main.vue?vue&type=template&id=60c0e69c"
import script from "./main.vue?vue&type=script&lang=js"
export * from "./main.vue?vue&type=script&lang=js"
import style0 from "./main.vue?vue&type=style&index=0&id=60c0e69c&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/paldia-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60c0e69c')) {
      api.createRecord('60c0e69c', component.options)
    } else {
      api.reload('60c0e69c', component.options)
    }
    module.hot.accept("./main.vue?vue&type=template&id=60c0e69c", function () {
      api.rerender('60c0e69c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/phase2/landing-page/view/main.vue"
export default component.exports