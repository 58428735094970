import i18n from '@/i18n';

const configFormApplicationRule = () => {
    return {
        configFieldTitle: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingApplicationRule.titleBlock'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        }
    };
};

export { configFormApplicationRule };
