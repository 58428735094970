<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingTargetProduct.titleTargetProduct') }}
                    </label>
                    <div class="col-sm-9">
                        {{ dataForm.title_target__product }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                        $t('landingPage.settingTargetProduct.detailTargetProduct')
                    }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.detail_target_product }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingTargetProduct.descriptionTargetProduct') }}
                    </label>
                    <div class="col-sm-9">
                        {{ dataForm.description_target_product }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingTargetProduct.imageTargetProduct') }}
                    </label>
                    <div class="col-sm-9">
                        <div v-if="dataForm.image_target_product?.origin_name" class="d-flex align-items-center my-2">
                            <a
                                target="_blank"
                                :href="dataForm.image_target_product?.path"
                                @click.prevent="downLoadFile(dataForm.image_target_product?.path, dataForm.image_target_product?.origin_name)"
                                >{{ dataForm.image_target_product?.origin_name }}</a
                            >
                        </div>

                        <b-img
                            v-if="dataForm.image_target_product?.path"
                            class=""
                            thumbnail
                            fluid
                            :src="dataForm.image_target_product?.path"
                            :alt="dataForm.image_target_product.origin_name"
                            rounded
                            style="width: 150px; height: 150px; object-fit: cover; object-position: center"
                        ></b-img>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                        $t('landingPage.settingTargetProduct.linkTargetProduct')
                    }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.link_target_product }}
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { handleDownFile } from '@/utils/format';

export default {
    name: 'settingTargetProduct',
    components: {},
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {};
    },
    methods: {
        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        }
    }
};
</script>

<style lang="scss" scoped></style>
