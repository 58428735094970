<template>
    <div>
        <div class="col-sm-12">
            <div class="d-flex">
                <div class="col-sm-7">
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                            >{{ $t('landingPage.settingContact.titleBlock') }} <span class="text-danger">*</span></label
                        >
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataForm.block_contact.title_block"
                                :config.sync="configForm.configFieldTitleBlockContact"
                                @blur="handleValidateField(configForm.configFieldTitleBlockContact)"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.background') }}</label>
                        <div class="col-sm-9">
                            <div>
                                <UploadFileCommon
                                    @dataFile="handleFileBackgroundImgContact"
                                    title="ファイルアップロード"
                                    :id="`BackgroundImgContact`"
                                    :config="{
                                        maxSize: 2048
                                    }"
                                />
                                <div v-if="dataForm.block_contact.background_image?.origin_name" class="d-flex align-items-center my-2">
                                    <a
                                        target="_blank"
                                        :href="dataForm.block_contact.background_image?.path"
                                        @click.prevent="
                                            handleDownFile(
                                                dataForm.block_contact.background_image?.path,
                                                dataForm.block_contact.background_image?.origin_name
                                            )
                                        "
                                        >{{ dataForm.block_contact.background_image?.origin_name }}</a
                                    >
                                    <span
                                        class="ml-2"
                                        style="cursor: pointer; font-size: 20px; z-index: 99"
                                        @click="dataForm.block_contact.background_image = null"
                                        ><i class="ri-close-line" style="vertical-align: bottom"></i
                                    ></span>
                                </div>
                            </div>
                            <div class="my-3">
                                <InputText
                                    style="max-width: 100px"
                                    :model.sync="dataForm.block_contact.background_color"
                                    :config="{
                                        type: 'color',
                                        classCustom: 'form-control-color',
                                        error: false
                                    }"
                                />
                            </div>
                            <div>
                                <InputCheckBox
                                    :model.sync="dataForm.block_contact.is_repeat"
                                    :id="`check-repeat-background-contact`"
                                    :value="dataForm.block_contact.is_repeat"
                                    :label="`repeat`"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.style') }} </label>
                        <div class="col-sm-9">
                            <Multiselect
                                :id="`select-contact_setting-style`"
                                :value.sync="dataForm.block_contact.style"
                                :options="listStyleContact"
                                :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                                @select="handleSelectTypeContact"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.contactOfficeName') }}</label>
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataForm.block_contact.contact_secretariat_name"
                                :config.sync="configForm.configFieldContactOfficeName"
                                @blur="handleValidateField(configForm.configFieldContactOfficeName)"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.tel') }} </label>
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataForm.block_contact.tel"
                                :config.sync="configForm.configFieldTel"
                                @blur="handleValidateField(configForm.configFieldTel)"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.mail') }}</label>
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataForm.block_contact.mail"
                                :config.sync="configForm.configFieldMail"
                                @blur="handleValidateField(configForm.configFieldMail)"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.detail') }} </label>
                        <div class="col-sm-9">
                            <Editor
                                v-if="isVisibleEditor"
                                :id="`editorStyleContact`"
                                :isBackgroundImageEditor="false"
                                :model.sync="dataForm.block_contact.html_content"
                                :modelEvents="`change keydown blur focus paste`"
                                :configInit="configEditor"
                                @init="onEditorInit"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-sm-5">
                    <PreviewContact :contentHTML="dataForm.block_contact.html_content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { InputText, InputCheckBox } from '@/components/Input';
import { handleDownFile } from '@/utils/format';
import { validateField } from '@/utils/validate';
import { LIST_STYLE_CONTACT } from '../../constants';

import Editor from '@/components/Editor/main.vue';
import PreviewContact from './preview.vue';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'SettingContact',
    components: {
        InputText,
        InputCheckBox,
        Editor,
        PreviewContact,
        UploadFileCommon,
        Multiselect
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            isVisibleEditor: false,
            objEditor: null,
            configEditor: {
                height: 600
            },
            listStyleContact: LIST_STYLE_CONTACT
        };
    },
    methods: {
        ...handleDownFile,

        handleValidateField(configField) {
            validateField(configField);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleFileBackgroundImgContact(file) {
            this.dataForm.block_contact.background_image = file;
        },

        handleSelectTypeContact() {
            // this.handleShotCodeStyle();
        }
    },
    mounted() {
        setTimeout(() => {
            this.isVisibleEditor = true;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
