<template>
    <div>
        <div class="d-flex align-items-start mt-3">
            <button v-if="!noChild" class="btn btn-success mr-3" type="button" @click="addChild(child)">+</button>
            <div class="w-100">
                <div class="d-flex">
                    <input class="form-control" v-model="child.value" />
                    <button type="button" @click="$emit('deleteChild')" class="btn btn-danger ml-2" style="white-space: nowrap">削除</button>
                </div>
                <master-child
                    v-for="(grandchild, grandchildIndex) in child.data"
                    @deleteChild="deleteChild(grandchildIndex)"
                    :key="grandchildIndex"
                    :child="grandchild"
                ></master-child>
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
export default {
    name: 'master-child',
    props: ['child', 'noChild'],

    methods: {
        deleteChild(childIndex) {
            console.log(childIndex);
            this.child.data.splice(childIndex, 1);
        },
        addChild(parent) {
            parent.data.push({
                value: '',
                data: []
            });
        }
    }
};
</script>

<style lang="scss"></style>
