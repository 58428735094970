var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-sm-7"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3 text-nowrap",staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(_vm.$t('landingPage.settingApplicationRule.titleBlock'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('InputText',{attrs:{"model":_vm.dataForm.block_application_rule.title_block,"config":_vm.configForm.configFieldTitle},on:{"update:model":function($event){return _vm.$set(_vm.dataForm.block_application_rule, "title_block", $event)},"update:config":function($event){return _vm.$set(_vm.configForm, "configFieldTitle", $event)},"blur":function($event){return _vm.handleValidateField(_vm.configForm.configFieldTitle)}}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3 text-nowrap",staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(_vm.$t('landingPage.settingApplicationRule.background')))]),_c('div',{staticClass:"col-sm-9"},[_c('div',[_c('UploadFileCommon',{attrs:{"title":"ファイルアップロード","id":`BackgroundImgLPApplicationRule`,"config":{
                                    maxSize: 2048
                                }},on:{"dataFile":_vm.handleFileBackgroundImgLP}}),(_vm.dataForm.block_application_rule.background_image?.origin_name)?_c('div',{staticClass:"d-flex align-items-center my-2"},[_c('a',{attrs:{"target":"_blank","href":_vm.dataForm.block_application_rule.background_image?.path},on:{"click":function($event){$event.preventDefault();return _vm.downLoadFile(
                                            _vm.dataForm.block_application_rule.background_image?.path,
                                            _vm.dataForm.block_application_rule.background_image?.origin_name
                                        )}}},[_vm._v(_vm._s(_vm.dataForm.block_application_rule.background_image?.origin_name))]),_c('span',{staticClass:"ml-2",staticStyle:{"cursor":"pointer","font-size":"20px"},on:{"click":function($event){_vm.dataForm.block_application_rule.background_image = null}}},[_c('i',{staticClass:"ri-close-line",staticStyle:{"vertical-align":"bottom"}})])]):_vm._e()],1),_c('div',{staticClass:"my-3"},[_c('InputText',{staticStyle:{"max-width":"100px"},attrs:{"model":_vm.dataForm.block_application_rule.background_color,"config":{
                                    type: 'color',
                                    classCustom: 'form-control-color',
                                    error: false
                                }},on:{"update:model":function($event){return _vm.$set(_vm.dataForm.block_application_rule, "background_color", $event)}}})],1),_c('div',[_c('InputCheckBox',{attrs:{"model":_vm.dataForm.block_application_rule.is_repeat,"id":`check-repeat-background-app_rule`,"value":_vm.dataForm.block_application_rule.is_repeat,"label":`repeat`},on:{"update:model":function($event){return _vm.$set(_vm.dataForm.block_application_rule, "is_repeat", $event)}}})],1)])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3 text-nowrap",staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(_vm.$t('landingPage.settingApplicationRule.detail'))+" ")]),_c('div',{staticClass:"col-sm-9"},[(_vm.isVisibleEditor)?_c('Editor',{attrs:{"id":`editorStyleApplicationRule`,"isBackgroundImageEditor":false,"model":_vm.dataForm.block_application_rule.html_content,"modelEvents":`change keydown blur focus paste`,"configInit":_vm.configEditor},on:{"update:model":function($event){return _vm.$set(_vm.dataForm.block_application_rule, "html_content", $event)},"init":_vm.onEditorInit}}):_vm._e()],1)])]),_c('div',{staticClass:"col-sm-5"},[_c('PreviewAppRule',{attrs:{"contentHTML":_vm.dataForm.block_application_rule.html_content}})],1)])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }