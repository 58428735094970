<template>
    <div>
        <Layout>
            <div v-if="!isLoading" class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-body">
                        <b-tabs
                            v-model="modelTab"
                            vertical
                            justified
                            nav-class="nav-tabs-custom"
                            content-class="p-3 text-muted scroll--content__landingpage"
                        >
                            <b-tab v-for="(tab, index) in TABS" :key="index" class="border-0">
                                <template v-slot:title>
                                    <span class="">{{ tab.name }}</span>
                                </template>
                                <div>
                                    <SettingBasicLP v-if="tab.id == 1" :dataForm="formData" />
                                    <SettingFirstViewAndTimeApplication v-else-if="tab.id == 2" :dataForm="formData" />
                                    <!-- <SettingPrize v-else-if="tab.id == 3" :dataForm="formDataEventPrizes" :masterTypes="masterTypeTitlePrize" /> -->
                                    <SettingTargetProduct v-else-if="tab.id == 4" :dataForm="formData" />
                                    <!-- <SettingDesign v-else-if="tab.id == 7" :dataForm="formDataFirstDesign" /> -->
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <Footer>
                    <div class="d-flex justify-content-center">
                        <div class="text-center flex-fill">
                            <button type="submit" @click="handleCancel" class="btn btn-light mr-3">{{ $t('btn.cancel') }}</button>
                            <button type="submit" @click="handleGoTo" class="btn btn-primary">{{ $t('btn.edit') }}</button>
                        </div>
                        <div v-if="$route.query.id">
                            <button type="button" class="btn btn-danger ml-3" @click="handleDeleteLP({ id: $route.query.id })">
                                {{ $t('btn.delete') }}
                            </button>
                        </div>
                    </div>
                </Footer>
            </div>
            <div class="d-flex justify-content-center" v-else>
                <LoadingIcon />
            </div>
        </Layout>
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import SettingBasicLP from './components/setting-basic/main.vue';
import SettingFirstViewAndTimeApplication from './components/setting-first_view/main.vue';
// import SettingPrize from './components/setting-prize/main.vue';
import SettingTargetProduct from './components/setting-target_product/main.vue';
// import SettingDesign from './components/setting-design/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Swal from 'sweetalert2';

import { TABS, initFormRegister } from '../constants';
import { masterMethods, landingPageMethods } from '@/state/helpers';
import { handleDataResponse } from '../handlers';
import { showMessage } from '@/utils/messages';

export default {
    name: 'ViewLandingPage',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        SettingBasicLP,
        SettingFirstViewAndTimeApplication,
        // SettingPrize,
        SettingTargetProduct,
        // SettingDesign,
        LoadingIcon
    },
    data() {
        return {
            TABS: TABS,
            formData: initFormRegister(),
            modelTab: 0,
            isLoading: false
        };
    },
    methods: {
        ...masterMethods,
        ...landingPageMethods,

        handleCancel() {
            this.$router.push({
                path: '/landing-page/list'
            });
        },

        handleGoTo() {
            this.$router.push({
                path: '/landing-page/edit',
                query: {
                    id: this.$route.query.id
                }
            });
        },

        async handleDeleteLP(payload) {
            Swal.fire({
                title: 'このキャンペーンを削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                cancelButtonText: 'キャンセル',
                confirmButtonText: 'OK'
            }).then(async (result) => {
                if (result.value) {
                    try {
                        const response = await this.deleteLandingPage(payload);
                        if (response.code !== 200) return;
                        this.$router.push({ path: '/landing-page/list' }).then(() => {
                            showMessage(response.code, this.$bvToast, 'キャンペーンが削除されました。');
                        });
                    } catch (error) {
                        console.log('error', error);
                    }
                }
            });
        },

        async getDetail() {
            if (!this.$route?.query?.id) return;
            this.isLoading = true;
            try {
                const response = await this.getDetailLandingPage(this.$route.query.id);
                handleDataResponse(this.formData, response);
                console.log('response', response);
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
        }
    },
    mounted() {
        this.getDetail();
    }
};
</script>

<style lang="scss">
.scroll--content__landingpage {
    height: 74vh;
    overflow-y: auto;
}
</style>
