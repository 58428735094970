<template>
    <div>
        <div v-if="!isAction" class="text-right mb-3">
            <button type="button" class="btn btn-outline-success rounded" @click="handleAddBlockFree()">
                <i class="fa fa-plus"></i>
                {{ $t('landingPage.settingFreeBlock.add') }}
            </button>
        </div>
        <div v-if="!isAction" style="max-height: 580px; overflow: auto">
            <div class="table-responsive">
                <table class="table mb-0 table-bordered table-box">
                    <thead>
                        <tr>
                            <th>{{ $t('landingPage.settingFreeBlock.title') }}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody v-if="dataForm.free_blocks.length > 0">
                        <template v-for="(item, k) in dataForm.free_blocks">
                            <Transition name="slide-fade__table--list" appear :key="item.id">
                                <tr>
                                    <td class="truncate-cell" style="text-align: left">
                                        <p class="truncate-cell" style="min-width: 180px; max-width: 500px; white-space: nowrap">
                                            {{ item.title }}
                                        </p>
                                    </td>
                                    <td align="center">
                                        <div class="row-data-small">
                                            <a
                                                href="javas
                                            cript:void(0)"
                                                class="text-info"
                                                @click="handleEditTitleFree(item)"
                                                >{{ $t('btn.edit') }}</a
                                            >
                                        </div>
                                    </td>
                                    <td align="center">
                                        <div class="row-data-small">
                                            <a href="javascript:void(0)" class="text-danger" @click="handleDeleteTitleFree(k)">{{
                                                $t('btn.delete')
                                            }}</a>
                                        </div>
                                    </td>
                                </tr>
                            </Transition>
                        </template>
                    </tbody>
                    <tbody v-else>
                        <template>
                            <Transition name="slide-fade__table--list" appear>
                                <tr style="position: relative; height: 80px">
                                    <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                        <p class="mt-3 text-center">
                                            {{ $t('notificationCommon.noData') }}
                                        </p>
                                    </td>
                                </tr>
                            </Transition>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else>
            <ActionFreeBlock :dataTitle="objAction" @cancel="handleCancelAction" @addData="handleAddData" @updateData="handleUpdateData" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingFreeBlocks',
    components: {
        ActionFreeBlock: () => import('./action-free_block.vue')
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            isAction: false,
            objAction: null
        };
    },
    methods: {
        handleAddBlockFree() {
            const handleMakeId =
                this.dataForm.free_blocks.length <= 0
                    ? this.dataForm.free_blocks.length + 1
                    : Math.max(...this.dataForm.free_blocks.map((item) => item.id || item.make_id)) + 1;
            this.objAction = {
                make_id: handleMakeId,
                typeAction: 'Add'
            };
            this.isAction = true;
        },

        handleAddData(data) {
            this.dataForm.free_blocks.push(data);
            this.isAction = false;
        },

        handleUpdateData(data) {
            const hasConfigId = data?.id ?? data.make_id;
            let indexTitle = this.dataForm.free_blocks.findIndex((item) => {
                const hasIdList = item?.id ?? item.make_id;
                return hasIdList == hasConfigId;
            });
            if (indexTitle <= -1) return;
            this.dataForm.free_blocks[indexTitle] = data;
            this.isAction = false;
        },

        handleEditTitleFree(obj) {
            this.objAction = obj;
            this.isAction = true;
        },

        handleDeleteTitleFree(idx) {
            this.dataForm.free_blocks.splice(idx, 1);
        },

        handleCancelAction() {
            this.isAction = false;
        }
    },

    mounted() {}
};
</script>

<style lang="scss" scoped></style>
