import i18n from '@/i18n';

const configFormContact = () => {
    return {
        configFieldTitleBlockContact: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingContact.titleBlock'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldContactOfficeName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingContact.contactOfficeName'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldTel: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingContact.tel'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max10']
        },
        configFieldMail: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingContact.mail'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255', 'email']
        }
    };
};

export { configFormContact };
