<template>
    <div>
        <div class="col-sm-12">
            <div class="d-flex">
                <div class="col-sm-7">
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingReceiptPhotography.title') }} </label>
                        <div class="col-sm-9">
                            <Editor
                                v-if="isVisibleEditor"
                                :id="`editorStylePrize`"
                                :isBackgroundImageEditor="false"
                                :model.sync="dataForm.block_receipt_photography_tutorial"
                                :modelEvents="`change keydown blur focus paste`"
                                :configInit="configEditor"
                                @init="onEditorInit"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-sm-5">
                    <PreviewReceiptPhotography :contentHTML="dataForm.block_receipt_photography_tutorial" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Editor from '@/components/Editor/main.vue';
import PreviewReceiptPhotography from './preview.vue';

export default {
    name: 'SettingReceiptPhotography',
    components: {
        Editor,
        PreviewReceiptPhotography
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            isVisibleEditor: false,
            objEditor: null,
            configEditor: {
                height: 600
            }
        };
    },
    methods: {
        onEditorInit(editor) {
            this.objEditor = editor;
        }
    },
    mounted() {
        setTimeout(() => {
            this.isVisibleEditor = true;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
