<template>
    <div>
        <div class="col-sm-12">
            <div class="d-flex">
                <div class="col-sm-7">
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                            >{{ $t('landingPage.settingApplicationRule.titleBlock') }} <span class="text-danger">*</span></label
                        >
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataForm.block_application_rule.title_block"
                                :config.sync="configForm.configFieldTitle"
                                @blur="handleValidateField(configForm.configFieldTitle)"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationRule.background') }}</label>
                        <div class="col-sm-9">
                            <div>
                                <UploadFileCommon
                                    @dataFile="handleFileBackgroundImgLP"
                                    title="ファイルアップロード"
                                    :id="`BackgroundImgLPApplicationRule`"
                                    :config="{
                                        maxSize: 2048
                                    }"
                                />
                                <div v-if="dataForm.block_application_rule.background_image?.origin_name" class="d-flex align-items-center my-2">
                                    <a
                                        target="_blank"
                                        :href="dataForm.block_application_rule.background_image?.path"
                                        @click.prevent="
                                            downLoadFile(
                                                dataForm.block_application_rule.background_image?.path,
                                                dataForm.block_application_rule.background_image?.origin_name
                                            )
                                        "
                                        >{{ dataForm.block_application_rule.background_image?.origin_name }}</a
                                    >
                                    <span
                                        class="ml-2"
                                        style="cursor: pointer; font-size: 20px"
                                        @click="dataForm.block_application_rule.background_image = null"
                                        ><i class="ri-close-line" style="vertical-align: bottom"></i
                                    ></span>
                                </div>
                            </div>
                            <div class="my-3">
                                <InputText
                                    style="max-width: 100px"
                                    :model.sync="dataForm.block_application_rule.background_color"
                                    :config="{
                                        type: 'color',
                                        classCustom: 'form-control-color',
                                        error: false
                                    }"
                                />
                            </div>
                            <div>
                                <InputCheckBox
                                    :model.sync="dataForm.block_application_rule.is_repeat"
                                    :id="`check-repeat-background-app_rule`"
                                    :value="dataForm.block_application_rule.is_repeat"
                                    :label="`repeat`"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationRule.detail') }} </label>
                        <div class="col-sm-9">
                            <Editor
                                v-if="isVisibleEditor"
                                :id="`editorStyleApplicationRule`"
                                :isBackgroundImageEditor="false"
                                :model.sync="dataForm.block_application_rule.html_content"
                                :modelEvents="`change keydown blur focus paste`"
                                :configInit="configEditor"
                                @init="onEditorInit"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-sm-5">
                    <PreviewAppRule :contentHTML="dataForm.block_application_rule.html_content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { configModalActionAppMethod } from './constants';
import { handleDownFile } from '@/utils/format';
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';

import UploadFileCommon from '@/components/Uploads/Files/main.vue';
import Editor from '@/components/Editor/main.vue';
import PreviewAppRule from './preview.vue';

export default {
    name: 'SettingApplicationRule',
    components: {
        InputText,
        UploadFileCommon,
        InputCheckBox,
        Editor,
        PreviewAppRule
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            configModalActionAppMethod: configModalActionAppMethod,
            objEditor: null,
            isVisibleEditor: false,
            configEditor: {
                height: 600
            }
        };
    },

    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleFileBackgroundImgLP(file) {
            this.dataForm.block_application_rule.background_image = file;
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        }
    },
    watch: {},
    mounted() {
        setTimeout(() => {
            this.isVisibleEditor = true;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
