<template>
    <div>
        <div class="d-flex align-items-start mt-3">
            <button v-if="!noChild" :disabled="!dis" class="btn btn-success mr-3" type="button" @click="addChild(child)">+</button>
            <div class="w-100">
                <div class="d-flex">
                    <input :disabled="!dis" class="form-control" v-model="child.value" />
                    <button v-if="noChild" type="button" @click="goEdit(child)" class="btn btn-primary ml-2" style="white-space: nowrap">詳細</button>
                    <button :disabled="!dis" type="button" @click="$emit('deleteChild')" class="btn btn-danger ml-2" style="white-space: nowrap">
                        削除
                    </button>
                </div>
                <master-child-edit
                    :dis="dis"
                    @deleteChild="deleteChild(grandchildIndex)"
                    v-for="(grandchild, grandchildIndex) in child.data"
                    :key="grandchildIndex"
                    :child="grandchild"
                ></master-child-edit>
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
export default {
    name: 'master-child-edit',
    props: ['child', 'dis', 'parent', 'noChild'],

    methods: {
        deleteChild(childIndex) {
            this.child.data.splice(childIndex, 1);
        },
        goEdit(child) {
            this.$router.push({ path: '/master/rule1-edit', query: { id: child.id } });
        },
        addChild(parent) {
            parent.data.push({
                value: '',
                data: []
            });
        }
    }
};
</script>

<style lang="scss"></style>
